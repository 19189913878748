body {
  margin: 0px;
  padding: 0px;
}
.header-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  padding: 1rem;
  font-size: 15px;
  background-color: #050405;
  border: 1px solid #ccc;
  box-sizing: 0 2px 2px rgba(0, 0, 0, 0.2);
  text-align: center;
  align-items: center;
  color: white;
}
.header-wrapper .logo .brand {
  color: #fff;
  text-decoration: none;
}
.header-wrapper .nav-wrapper {
  display: flex;
  flex-direction: row;
}
.header-wrapper .nav-wrapper .activeClass {
  border-bottom : 1px solid red;
}
.header-wrapper .nav-wrapper .navigation,
.header-wrapper .nav-wrapper .navigation a,
.header-wrapper .nav-wrapper .navigation a:focus,
.header-wrapper .nav-wrapper .navigation a:hover {
  color: #fff;
  padding: 5px 10px;
  text-decoration: none;
}
.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 30px;
  column-gap: 60px;
  background-color: #f2f2f2;
}
.form-group {
  margin-bottom: 30px;
}
.header-group {
  margin-bottom: 20px;
}
.form-horizontal.custom-horizontal-form .form-group {
  margin-right: 0;
  margin-left: 0;
}
.form-control {
  font-size:small;
  height: 39px;
}
input[type=submit]:hover {
  background-color: #45a049;
}
.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}
.col-50 {
  float: left;
  width: 50%;
  margin-top: 6px;
}
.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}
.row::after {
  content: "";
  display: table;
  clear: both;
}
@media screen and (max-width: 600px) {
  .col-25,.col-50, .col-75, input[type=submit] {
    width: 100%;
    margin-top: 0;
  }
}
input[type=text], select, textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}
.btn-group {
 margin-bottom: 10px;
 overflow-wrap: break-word;
}
.fbtn {
  margin: 10px;
  background-color: #7ebff8;
  font-size: 12px;
  font-weight: 500;
  height: 35px;
}
.addbtn {
  background-color: #7ebff8;
  font-size: 12px;
  font-weight: 500;
  height: 35px;
}
.btn-accelerator {
  background-color: #4e7ebd;
  min-width: 50px;
  margin-right: 30px;
}
/*STYLE.LESS */
.left-panel-action {
  text-align: left;
  padding: 0px;
}
.right-panel-action {
  text-align: right;
  padding: 0px;
}
.right-panel-action .search-input-box,
.custom-date-input {
  max-width: 200px;
  display: inline-block;
  position: relative;
  top: 2px;
  margin-right: 5px;
  margin-left: 5px;
}
.right-panel-action .search-input-box .form-control {
  border: 0px;
  border-bottom: 1px solid ;
  border-radius: 0px;
  background-color: transparent;
  box-shadow: none;
}
.right-panel-action .search-input-box input {
  width: 90px;
  height: 30px;
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
  padding-left: 25px;
}
.right-panel-action .search-input-box input:focus {
  width: 200px;
  outline: none;
  border-color: #66afe9;
}
.right-panel-action .search-input-box i {
  position: absolute;
  top: 1px;
  left: 2px;
  background: transparent;
  padding: 5px;
  cursor: pointer;
}
.CircularProgressStyle {
  position: fixed;
  left: 49%;
  top: 49%;
  color: #b00b0b !important;
}
.inputSpinner {
  position: absolute;
  top: 2px;
  right: 45px;
  height: 0px;
  border-radius: 0em;
}
.config-block {
background-color: #F4F4F4;
padding: 20px;
border-radius: 8px;
}
.active {
  background-color: rgb(59, 57, 57);
  color: #fff;
}
#blockTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffffa3;
}
.filter-bg {
  background-color: #80bdff ;
  padding: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}
.filter-label {
  margin-top: -6px;
}
.control-label {
  text-align: right;
  margin-top: 6px;
}
/* .container {
  overflow-x: auto;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  padding: 10px;
} */
/* .app-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1rem;
  width: 100%;
  margin: 0 auto; 
}  */
.wrap {
  margin-top: 2%;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  height: 50%;
}
table {
  border-collapse: collapse;
  width: 100%;
  height: 100%;
  align-self: center;
}

th,
td {
  border: 1px solid #ffffff;
  text-align: left;
  padding: 1px;
  font-size: 15px;
  width: 500px;
}

th {
  background-color: rgb(183, 184, 185);
  height: 40px;
}

td {
  background-color: rgb(243, 243, 245);
  height: 40px;
  width: 500px;
}
.ml1 {
  margin-left: 9px;
}